.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.buttons {
  padding-left: 10px;
  padding-right: 10px;
}

.standardDiv {
  padding-left: 10px;
  height: 91.3vh;
  width: 96vh;
  overflow: auto;

 
}
.standardDiv2 {
  padding-right: 8px;
  height: 91.3vh;
  width: 120vh;
  overflow: auto;


}
.innerDiv1 {
  padding-left: 10px;
  height: 91.3vh;
  width: 107vh;
  overflow: auto;

  
}
.containerfortop {
  gap: 100px; 
  display: flex;
  justify-content: space-between; /* Aligns items to the ends of the container */
  align-items: center; /* Aligns items vertically within the container */
}

.dropdown {
  width: 140px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
}


.submitButton {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 9px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); /* Add shadows */
}

.submitButton:hover {
  background-color: #45a049; /* Darker green on hover */
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 12px 40px 0 rgba(0,0,0,0.19); /* Add stronger shadows on hover */
}
/* Style for dropdown options */
.dropdown option {
  background-color: #fff;
  color: #333;
}

/* Style for dropdown arrow */
.dropdown::after {
  content: '\25BC'; /* Unicode character for down arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.overlay-content h2 {
  margin-bottom: 10px;
}

.overlay-content p {
  font-size: 18px;
  margin-bottom: 20px;
}


.mainArea {
    display: grid;
    height: 200;
    box-sizing: border-box;
    border: 1px solid #000;
    grid-template-columns: 1fr 1fr; /* Equal width columns */
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
